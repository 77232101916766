import { useState } from "react";

import Footer from "./Components/Footer";
import Header from "./Components/Header";
import MobileHeader from "./Components/MobileHeader";
import f1 from "./images/f1.png";
import f2 from "./images/f2.png";
import f3 from "./images/f3.png";
import { CountryDropdown } from "react-country-region-selector";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { BsCircleFill, BsCalendar2Date } from "react-icons/bs";
import { RiArrowDropDownLine } from 'react-icons/ri';
import { BiTimeFive } from "react-icons/bi";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
// import AD from './images/ad1.svg'
import { useForm, ValidationError } from "@formspree/react";
import Carousels from "./Components/Carousels";


function Home() {

    const [formData, setFormData] = useState({
        selectedCountry: '',
        selectedDate: '',
        selectedAdvertisementFormat: '',
    });
    const [showSubmit, setShowSubmit] = useState(true);

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        // Get today's date in the format "YYYY-MM-DD"
        const today = new Date().toISOString().split('T')[0];

        // Check if the selected date is not in the past
        if (name === 'selectedDate' && value < today) {
            console.log('Cannot select past dates');
            return;
        }

        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const [state, handleSubmit] = useForm("xbjvwznl");
    const [visible, setVisible] = useState(false);




    const handleVisible = () => {
        const { selectedCountry, selectedDate, selectedAdvertisementFormat } = formData;
        if ((selectedCountry && selectedDate) && selectedAdvertisementFormat) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    const handleHidden = () => {
        setTimeout(() => {
            setVisible(false);
        }, 3000);
    };

    return (
        <>
            <div className="xl:mx-20 md:mx-10 mx-4">
                <Header />
                <div className="flex flex-col px-8 w-full h-full py-10">
                    <div>
                        <p className="md:text-[3rem] text-[20px] italic text-[#6d6d6d]  Ramillas text-center">
                            The Epitome of
                        </p>
                        <p className="md:text-[4rem] text-[#343434] text-[46px] leading-[3rem] md:leading-normal md:mt-[-20px] Ramillas text-center">
                            Luxury Advertising.
                        </p>
                    </div>
                    <div className="md:flex gap-8 md:mt-9 mt-6">
                        <div>
                            <img
                                className="h-[80%] w-full object-cover"
                                src={f1}
                                alt="first"
                            />
                        </div>
                        <div>
                            <img
                                className="h-[80%] w-full object-cover mt-4 md:mt-0"
                                src={f2}
                                alt="second"
                            />
                        </div>
                        <div className="">
                            <img
                                className="h-[80%] w-full object-cover mt-4 md:mt-0"
                                src={f3}
                                alt="third"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col text-center md:w-[70%] md:pt-0 pt-8 mx-auto pb-8 font-light">
                        <p className="text-[#424242] md:text-[1rem] ">
                            Everidoor unveils a realm of opulence that exceeds
                            every anticipation. <br />
                            Our Luxury Display Network seamlessly merges
                            sophistication with innovation, offering a
                            captivating stage where your brand's story elegantly
                            unfurls on digital screens that epitomize
                            extravagance.
                        </p>
                    </div>
                </div>
                <form
                    action="https://formspree.io/f/xbjvwznl"
                    method="POST"
                    onSubmit={handleSubmit}
                    className=" w-[100%] text-sm"
                >
                    <div className="flex-col w-[100%]  justify-center">
                        <div className="md:mt-1 mt-8 md:mx-0 mx-4">
                            <h1 className="md:text-[1.5rempx] text-[1.25rem] text-center font-light">
                                PLAN YOUR EXPERIENCE
                            </h1>
                            <p className="md:text-[1rem] text-[1rem] text-center font-light w-full mt-4 opacity-40">
                                Enter your details below and our team will contact
                                you
                            </p>
                        </div>

                        <div className="border  border-gray-400/80 md:mx-20 mx-4 h-full text-[1rem] mt-6 grid md:grid-cols-3 grid-cols-1 text-gray-500 gap-2 py-1 divide-x">
                            <div className="flex   items-center h-full text-[1rem] px-3">

                                <div className="flex items-center w-full px-3 ">
                                    <HiOutlineLocationMarker className="mr-4 w-[18px]" />
                                    <select
                                        name="selectedCountry"
                                        id="Country"
                                        className=" md:w-full cursor-pointer w-full border-none appearance-none  bg-white text-black focus:outline-none py-1 "
                                        required
                                        value={formData.selectedCountry}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select your Country</option>
                                        <option value="India">India</option>
                                        <option value="United States">United States</option>
                                        <option value="Canada">Canada</option>
                                        <option value="United Kingdom">United Kingdom</option>
                                    </select>
                                    <RiArrowDropDownLine size={35} />
                                </div>
                            </div>

                            <div className="flex  items-center h-full px-3 ">
                                <div className="flex items-center px-2 border-b  md:border-none w-full">
                                    <BsCalendar2Date className="mr-3 w-8" />
                                    <label htmlFor="date" className="">
                                        Date
                                    </label>
                                    <input
                                        name="selectedDate"
                                        id="date"
                                        className="w-full text-gray-400 cursor-pointer border-none appearance-none bg-white text-center focus:outline-none"
                                        value={formData.selectedDate}
                                        onChange={handleInputChange}
                                        type="date"
                                        required
                                        min={new Date().toISOString().split('T')[0]}
                                    />
    <RiArrowDropDownLine size={35} />
                                </div>
                            </div>

                            <div className="flex items-center h-full px-4 gap-2 md:gap-4">
                                <div className="flex items-center px-2 gap-3 w-full">
                                    <img src={"AD"} alt="" className="w-[18px] md:w-[24px] " />
                                    <select
                                        name="selectedAdvertisementFormat"
                                        value={formData.selectedAdvertisementFormat}
                                        onChange={handleInputChange}
                                        id="AdvertisementFormat"
                                        className=" md:w-[90%] cursor-pointer border-none appearance-none w-full bg-white text-black   focus:outline-none py-1 "
                                        required
                                    >
                                        <option value="">Format of your Advertisement</option>
                                        <option value="Video">Video</option>
                                        <option value="United States">Image</option>

                                    </select>
                                    <RiArrowDropDownLine size={35} />
                                </div>
                            </div>
                        </div>
                        <p className="opacity-40 text-[1rem] font-light md:ml-20 ml-4 mt-2">
                            Want to{" "}
                            <Link to={"/Subscribe"} className="underline underline-offset-4">
                                subscribe to our newsletter
                            </Link>
                            ?
                        </p>
                        <div className="flex justify-center md:mt-0 mt-8 text-[1rem]">
                            {showSubmit && (
                                <button
                                    onClick={handleVisible}
                                    type="button"
                                    className="py-1 px-8 border border-gray-400/80 opacity-80"
                                >
                                    Continue
                                </button>
                            )}
                        </div>
                        {visible ? (
                            <div className="flex w-[100%] items-center justify-center">
                                <div className={`  w-[90%]  h-full flex-col justify-center items-center`}>
                                    <div className="md:flex gap-8 w-full">
                                        <div className="md:w-1/2 ">
                                            <div className=" md:flex gap-5 items-center w-full">
                                                <div className="md:w-[15%] ">
                                                    <select
                                                        name="Salutation"
                                                        id="Salutation"
                                                        className="px-3 py-4 border cursor-pointer  w-full mt-4 focus:outline-none  text-gray-600"
                                                        required
                                                    >
                                                        <option value="">Title</option>
                                                        <option value="Mr.">Mr.</option>
                                                        <option value="Mrs.">Mrs.</option>
                                                    </select>
                                                </div>
                                                <div className="md:w-[90%]">
                                                    <input
                                                        type="text"
                                                        name="First Name"
                                                        id="fname"
                                                        placeholder="First Name"
                                                        className=" w-full border placeholder-gray-600  px-3 py-[14px] mt-3 focus:outline-none text-gray-600"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full  ">
                                                <input
                                                    type="text"
                                                    name="Last Name"
                                                    id="lname"
                                                    placeholder="Last name"
                                                    className=" w-full border px-3 py-4 mt-4 placeholder-gray-600  focus:outline-none text-gray-600"
                                                    required
                                                />
                                            </div>
                                            <div className="w-full">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Email"
                                                    className="w-full px-3 py-4 mt-4 border placeholder-gray-600  focus:outline-none text-gray-600"
                                                    required
                                                />
                                                <ValidationError
                                                    prefix="Email"
                                                    field="email"
                                                    errors={state.errors}
                                                />
                                            </div>
                                            <div className="w-full">
                                                <input
                                                    type="text"
                                                    name="Telephone"
                                                    id="Telephone"
                                                    placeholder="Phone number"
                                                    className="w-full px-3 py-4 mt-4 border placeholder-gray-600  focus:outline-none text-gray-600"
                                                    required
                                                />
                                            </div>
                                            <div className="w-full">
                                                <input
                                                    type="text"
                                                    name="Company"
                                                    id="Company"
                                                    placeholder="Company name"
                                                    className="w-full px-3 py-4 mt-4 border placeholder-gray-600  focus:outline-none text-gray-600"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="md:w-1/2 h-full">
                                            <div className="w-full mt-4 h-full">
                                                <textarea
                                                    name="Message"
                                                    id="message"
                                                    cols="30"
                                                    rows="16"
                                                    placeholder="Type your question..."
                                                    className="border focus:outline-none placeholder-gray-600  w-full p-2"
                                                    required
                                                ></textarea>

                                                <ValidationError
                                                    prefix="Message"
                                                    field="message"
                                                    errors={state.errors}
                                                />

                                            </div>
                                        </div>
                                    </div>

                                    <div className="md:flex w-full gap-8 mt-4">
                                        <div className="md:w-1/2">
                                            <select
                                                name="Unfamiliar"
                                                id="hear"
                                                className="border px-3 py-4 cursor-pointer placeholder-gray-600  w-full mt-4 focus:outline-none  text-gray-600"
                                                required
                                            >
                                                <option value="">What best describes the kind of your organisation?</option>
                                                <option value="Article">Article</option>
                                                <option value="Newspaper">
                                                    Newspaper
                                                </option>
                                                <option value="Blog">Blog</option>
                                                <option value="Social Media">
                                                    Social Media
                                                </option>
                                                <option value="Advertisement">
                                                    Advertisement
                                                </option>
                                                <option value="Others">Others</option>
                                            </select>
                                        </div>
                                        <div className="md:w-1/2">
                                            <input
                                                type="text"
                                                name="Company"
                                                id="Company"
                                                placeholder="What type of advertisement are you enquiring for ?"
                                                className=" w-full px-3 py-4 mt-4 border placeholder-gray-600  focus:outline-none text-gray-600"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-8 flex gap-4 items-center">
                                        <input
                                            type="checkbox"
                                            name="checkbox"
                                            id="checkbox"
                                            className="h-4 w-4 rounded-sm cursor-pointer "
                                            required
                                        />
                                        <p className="cursor-pointer">
                                            I would like to receive updates from everidoor via email
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <button
                                            onClick={handleHidden}
                                            type="submit"
                                            className="py-4 px-16 hover:bg-[#6b2780]  text-gray-500 hover:text-white border-2 border-gray-500 font-semibold text-sm mt-8 relative left-[50%] -translate-x-[50%]"
                                        >
                                            SUBMIT
                                        </button>
                                    </div>

                                </div>
                            </div>)
                            : (null)
                        }
                        {state.succeeded ? (
                            <div className="flex items-center justify-center text-center  md:justify-start md:pl-20 pt-6">
                                <p className="text-lg">
                                    Form submitted with flair! Stay tuned for an enchanting
                                    message from our team.
                                </p>
                            </div>
                        ) : (null)}
                    </div>
                    <div className="h-full flex md:mt-40 mt-20 md:flex-row flex-col-reverse justify-center w-full mx-auto  lg:px-12">
                        <div className="flex flex-col md:w-1/2 items-center justify-center md:pr-16 md:pl-0">
                            <p className="lg:text-[2.5rem] text-[1.5rem] leading-[4rem] md:leading-normal Ramillas w-full md:pt-0 pt-8 md:text-left text-center font-medium">
                                Captivating the Elite
                            </p>
                            <p className="lg:text-[1rem] md:mt-8 mt-4 font-light">
                                Our digital screens find their homes in the
                                domains of the elite—places where Ultra High Net
                                Worth Individuals (UHNIs) converge. With
                                unwavering precision, we bring your message to
                                the eyes and hearts of those who understand the
                                essence of true luxury.
                            </p>
                            <a target='_himanshu' href="https://www.molog.co.in/Signin" className="underline underline-offset-4 lg:text-[1rem] text-[16px] my-8 tracking-wide w-full text-[#B48B05] Ramillas font-light md:text-left text-center hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                                Everidoor Dashboard
                            </a>
                        </div>
                        <div className="md:w-1/2">
                            <img
                                src="pexels-cottonbro-studio-6474924 1.png"
                                alt=""
                                className=""
                            />
                        </div>
                    </div>
                </form>
                <div className="h-full md:py-20 py-8">
                    <h1 className="text-center md:text-[3rem] text-[1.5rem] Ramillas">
                        Where Luxury Resides
                    </h1>
                    <Link to="/Location">
                        <p className="text-[#B48B05] mx-auto w-fit text-center underline underline-offset-4 text-[1rem] font-light Ramillas hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                            Locations
                        </p>
                    </Link>
                    <div className="flex items-center mt-8 justify-center">
                        <Carousels/>
                    </div>
                </div>

                <div>
                    <div className="h-full md:flex justify-center w-fit mx-auto lg:px-8">
                        <div className="flex flex-col md:w-1/2  justify-center xl:pr-20">
                            <p className="lg:text-[2.5rem] text-[1.5rem] leading-[4rem] md:leading-normal Ramillas w-full xl:pb-16 md:text-left text-center font-medium">
                                The Ultimate Showcase
                            </p>
                            <p className="lg:text-[1rem] md:mt-0 mt-4 font-light md:px-0">
                                Showcase your luxury offerings to an audience
                                that values excellence and uniqueness. Gain
                                access to a platform that perfectly embodies
                                your brand's philosophy and worth.
                            </p>
                            <Link to={"/Advertiser"}>
                                <p className="underline underline-offset-4 lg:text-[1rem] text-[16px] my-8 tracking-wide w-full text-[#B48B05] Ramillas font-light text-center md:text-left hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                                    Become an Advertiser
                                </p>
                            </Link>
                        </div>
                        <div className="md:w-1/2">
                            <img
                                src="wowmolog_52358_luxury_hotel_lobby_with_small_vertical_standing__1ec93fa9-0171-4845-a271-4bf267a0358f 1.png"
                                alt=""
                            />
                        </div>
                    </div>
                    <hr className="w-1/2 mx-auto mt-20" />
                    <h1 className="md:text-[1rem] text-[1rem] text-center md:pt-16 pt-8 font-light md:px-0 px-8">
                        At everidoor, we present a realm of luxury that
                        transcends expectations.
                    </h1>
                    <h1 className="md:text-[2.25rem] text-[1.5rem] md:mt-0 mt-4 text-center md:pb-12 mb-8 font-light md:px-0">
                        Elevating brands to the pinnacle of prestige and
                        aspiration.
                    </h1>
                    <hr className="w-5/6 mx-auto py-8" />
                    <div className="h-full md:flex justify-center w-fit mx-auto lg:px-8">
                        <div className="md:w-1/2">
                            <img
                                src="pexels-skitterphoto-2231814 1.png"
                                alt=""
                            />
                        </div>
                        <div className="flex flex-col md:w-1/2  justify-center md:pl-20">
                            <p className="lg:text-[2.5rem] text-[1.5rem] leading-[4rem] md:leading-normal Ramillas w-full xl:pb-16 md:text-left text-center font-medium md:mt-0 mt-4">
                                The Emblem of Distinction
                            </p>
                            <p className="lg:text-[1rem] md:mt-0 mt-4 font-light md:px-0">
                                Embrace an infusion of elegance and increased
                                revenue. Partner with Everidoor to introduce
                                carefully curated, high-impact advertisements to
                                your prestigious clientele.
                            </p>
                            <Link to={"/Partner"}>
                                <p className="underline underline-offset-4 lg:text-[1rem] text-[1rem] my-8 tracking-wide w-full text-[#B48B05] Ramillas font-light text-center md:text-left hover:text-[#F8C933] hover:cursor-pointer transition duration-300 ease-in-out">
                                    Become a Partner
                                </p>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="h-full md:py-20 py-8">
                    <div className="w-[100%] h-[40%] flex flex-col items-center justify-center md:py-16 py-6">
                        <motion.p>
                            <p className="font-primary md:text-[3rem] text-[30px] Ramillas text-center">
                                Our Dashboard as an exclusive bridge
                            </p>
                            <p className="md:text-[1rem] text-[16px] text-[#555555] px-8 text-center font-light md:mt-0 mt-4">
                                At everidoor, we present a realm of luxury that
                                transcends expectations.
                            </p>
                        </motion.p>
                    </div>
                    <div className="md:flex md:px-20 px-0 text-white relative">
                        <div
                            // style={{
                            //     backgroundImage: `url('everidoor (3) 2.png')`,
                            // }}
                            className=" bg-gradient-to-r from-[#2b033c] via-[#5d0389]  to-[#2b033c]  h-full w-full md:pt-20 md:pb-20 pt-8 md:mb-0 mb-4 md:flex justify-between relative items-center"
                        >
                            <p className="text-[1rem] font-thin md:ml-20 md:px-0 px-4">
                                Discover the transformative power of the
                                Everidoor Dashboard, a comprehensive platform
                                tailored for both luxury brands and prestigious
                                venues.
                                <br />
                                <br /> Effortlessly manage campaigns, screens,
                                and advertisements while gaining real-time
                                insights into performance and revenue.
                                <br />
                                <br />
                                Everidoor Dashboard streamlines your luxury
                                advertising experience and puts the power in
                                your hands.
                                <br />
                                <ul className="font-thin mt-8 ml-4">
                                    <li className="flex items-center">
                                        <span className="h-fit">
                                            <BsCircleFill
                                                size={4}
                                                className="opacity-70 mr-2"
                                            />
                                        </span>
                                        Unified Campaign Management
                                    </li>
                                    <li className="flex items-center">
                                        <span className="h-fit">
                                            <BsCircleFill
                                                size={4}
                                                className="opacity-70 mr-2"
                                            />
                                        </span>
                                        In-Depth Analytics
                                    </li>
                                    <li className="flex items-center">
                                        <span className="h-fit">
                                            <BsCircleFill
                                                size={4}
                                                className="opacity-70 mr-2"
                                            />
                                        </span>
                                        Exclusive Network
                                    </li>
                                    <li className="flex items-center">
                                        <span className="h-fit">
                                            <BsCircleFill
                                                size={4}
                                                className="opacity-70 mr-2"
                                            />
                                        </span>
                                        Access Smart Recommendations
                                    </li>
                                </ul>
                            </p>
                            <img
                                src="pexels-cottonbro-studio-3205504 1.png"
                                alt=""
                                className="h-[300px] md:w-[225px] w-full md:translate-x-[25%] md:mt-0 mt-4 object-cover"
                            />
                        </div>
                    </div>
                </div >
            </div >
            <Footer />
        </>
    );
}

export default Home;
